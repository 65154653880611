@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
.custom-navbar {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
  }
  
  .nav-container {
    width: 100%;
    padding-right: 300px;
  }
  
  .navbar-nav {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .collapse {
    margin-right: 25px;
  }
  
  .navlink {
    margin-left: 80px;
    margin-top: 8px;
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    color: rgba(59, 79, 96, 1);
  }

  .navlink:hover {
    color: rgb(152, 168, 173);
  }

  .socialTG {
    margin-left: 10px;
    margin-top: 4px;
  }

  .socialVK {
    margin-left: 100px;
  }

  .logo {
    margin-left: 200px;
  }

  .dropdown-menu {
    animation: fadeIn 0.5s;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .dropdowna {
    margin-top: 12px;
  }

  .dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    color: rgb(3,155,229);
    font-size: 20px;
    border-radius: 50%;
    border: none;
    height: 30px;
    width: 30px;
  }
  
  .dropdown:hover {
    background-color: rgb(3,155,229);
  }

  .dropdown:active {
    background-color: rgb(3,155,229);
  }

  .dropdown:focus {
    background-color: rgb(3,155,229);
  }

  .dropdown_item_1 {
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    color: rgba(59, 79, 96, 1);
    margin-top: 5px;
  }

  .dropdown_item {
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    color: rgba(59, 79, 96, 1);
  }

  .dropdown_item:hover {
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    color: rgba(59, 79, 96, 1);
  }

  .dropdown_img {
    margin-right: 10px;
  }

  .dropdown-menu:hover {
    background-color: rgb(240, 240, 240);
  }
  
  .dropdown-menu:hover .dropdown-item {
    color: rgba(59, 79, 96, 1);
    background-color: rgb(255, 255, 255);
  }

  @media (max-width: 1200px) {
    .socialTG {
      display: none;
    }

    .socialVK {
      display: none;
    }

    .dropdowna {
      display: none;
    }

    .logo {
      margin-left: 40px;
    }
  }

  @media (max-width: 770px) {
    .socialTG {
      display: none;
    }

    .socialVK {
      display: none;
    }

    .dropdowna {
      display: none;
    }

    .logo {
      margin-left: 10px;
    }

    .nav-container {
      padding-right: 20px;
    }

    .navlink {
      margin-left: 10px;
    }

    .navbar-nav {
      background-color: rgb(255, 255, 255);
    }
  }