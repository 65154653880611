.mobile_scheme {
    background-image: url(../pic/wowa.png);
    background-repeat: no-repeat;
    background-position: center 175px;
    height: 100%;
}

.mobile_column {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.mobile_row {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 150px;
    margin-right: -30px;
    margin-left: -30px;
}

.mobile_row p {
    font: 20px Podkova, serif;
    font-weight: 600;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
}

.mobile_main-prog-con {
    margin-top: 100px;
    text-align: center;
    width: 100%;
  }

  .mobile_semi-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .mobile_semi-item {
    display: flex;
    align-items: center;
    gap: 13px;
    height: 100%;
  }

  .mobile_semi-list-div {
    margin-top: 48px;
    justify-content: center;
    padding: 0 25%;
  }

  .mobile_triangle {
    padding-bottom: 14px;
  }

  .mobile_semi-text {
    font: 20px Podkova, serif;
    font-weight: 600;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
  }


@media (min-width: 1450px) {
    .mobile_scheme {
      display: none;
    }
  }

@media (max-width: 681px) {
    .mobile_scheme {
        background-image: none;
        background-repeat: no-repeat;
        background-position: center 210px;
        height: 100%;
    }

    .mobile_row {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      gap: 150px;
      margin-right: -45px;
      margin-left: -45px;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 50%;
        border: 10px solid rgba(202, 221, 228, 0.8);
        height: 200px;
        width: 200px;
        padding: 5px;
        margin: 0 auto;
      }
  }
}