.root {
    padding: 0;
    margin: 0;
    font-size: 18px;
  }
  
  body {
    font: menu;
    font-size: 1rem;
    line-height: 1.4;
    padding: 0;
    margin: 0;
  }
  section {
    padding-top: 4rem;
    width: 50%;
    margin: auto;
  }
  h1 {
    font-size: 2rem;
    font-weight: 500;
  }
  details[open] summary ~ * {
    animation: open 0.3s ease-in-out;
  }
  
  @keyframes open {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  details summary::-webkit-details-marker {
    display: none;
  }
  
  details summary {
    width: 100%;
    padding: 0.5rem 0;
    position: relative;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 300;
    list-style: none;
  }
  
  details summary:after {
    content: "+";
    color: black;
    position: absolute;
    font-size: 1.75rem;
    line-height: 0;
    margin-top: 0.75rem;
    right: 0;
    font-weight: 200;
    transform-origin: center;
    transition: 200ms linear;
  }
  details[open] summary:after {
    transform: rotate(45deg);
    font-size: 2rem;
  }
  details summary {
    outline: 0;
  }
  details p {
    font-size: 0.95rem;
    margin: 0 0 1rem;
    padding-top: 1rem;
  }

.firstquestion {
    margin-top: 50px;
}

.questions {
    margin-top: 20px;
}

.FAQheading-text {
    text-align: center;
    font: 32px Podkova, serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
  }

.mainFAQ {
    font: 20px Podkova, serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
}

.secondFAQ {
    font: 16px Rubik, sans-serif;
    font-weight: 400;
    color: rgba(81, 109, 133, 1);
    text-align: left;
    white-space: pre-wrap;
}

.FAQ-con{
  margin-top: 150px;
  background-color: #fff;
}

@media (max-width: 1200px) {
  section {
    padding-top: 4rem;
    width: 80%;
    margin: auto;
  }
}