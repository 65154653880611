.maindivway {
    margin-top: 80px;
    background-color: rgba(202, 221, 228, 1);
    width: 100%;
    height: 100%;
    min-height: 1500px;
}

.left {
    margin-top: 100px;
    margin-left: 35%;
    text-align: left;
    width: calc(100%/2);
    height: 150px;
  }
  
.right {
    margin-top: 50px;
    margin-right: 40%;
    text-align: center;
    width: calc(100%/2);
    height: 150px;
  }

.divflex {
    background-image: url(../../pic/01.png);
    background-size: 210px 140px;
    background-repeat: no-repeat;
    background-position: 34% 33%;
    margin-top: 80px;
    display: flex;
  }

.itemtext {
    width: 400px;
    flex: 0 0 auto;
    font: 16px Rubik, sans-serif;
    font-weight: 400;
    color: rgba(81, 109, 133, 1);
    text-align: left;
    white-space: pre-wrap;
}

.imgfirst {
    border-radius: 50%;
    height: 250px;
    width: 250px;
}

.right1 {
    margin-right: 35%;
    margin-left: 20px;
    margin-top: 90px;
    text-align: left;
    width: calc(100%/2);
    height: 150px;
  }
  
.left1 {
    margin-left: 35%;
    margin-top: 40px;
    text-align: center;
    width: calc(100%/2);
    height: 150px;
  }

.divflex1 {
    background-image: url(../../pic/02.png);
    background-size: 210px 140px;
    background-repeat: no-repeat;
    background-position: 61% 33%;
    margin-top: 80px;
    display: flex;
  }

  .divflex2 {
    background-image: url(../../pic/03.png);
    background-size: 210px 140px;
    background-repeat: no-repeat;
    background-position: 34% 33%;
    margin-top: 80px;
    display: flex;
  }

  .divflex3 {
    background-image: url(../../pic/04.png);
    background-size: 210px 140px;
    background-repeat: no-repeat;
    background-position: 61% 33%;
    margin-top: 80px;
    display: flex;
  }

.button-container{
    padding-top: 140px;
    display: flex;
    justify-content: center;
}

.smth {
    margin-top: 40px;
}

.item-header {
    font: 24px Podkova, serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
}

@media (max-width: 1030px) {
  .maindivway {
    display: none;
  }
}