.gcontainer {
    margin-top: 120px;
    width: 100%;
  }
  
  .gtoppane {
    text-align: center;
    width: 100%;
    height: 150px;
  }
  
  .gleftpane {
    text-align: center;
    width: calc(100%/5);
    height: 300px;
    margin-left: 300px;
  }
  
  .gmiddlepane {
    text-align: center;
    width: calc(100%/5);
    height: 300px;
  }
  
  .grightpane {
    text-align: center;
    width: calc(100%/5);
    height: 300px;
    margin-right: 300px;
  }
  
  body {
    margin: 0!important;
  }
  
  .gd-flex {
    display: flex;
  }
  
  .gheading-text {
    font: 32px Podkova, serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
  }
  
  .gsecondary-text {
    margin-top: 35px;
    font: 17px Rubik, sans-serif;
    font-weight: 400;
    color: rgba(81, 109, 133, 1);
    white-space: pre-wrap;
  }
  .best {
    color: rgba(160, 182, 50, 1);
  }
  .all-psycho {
    font: 20px Podkova, serif;
    font-weight: 600;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
  }

  @media (max-width: 1600px) {
    .gleftpane {
      display: none;
    }
    
    .grightpane {
      display: none;
    }

    .gmiddlepane {
      text-align: center;
      width: calc(100%/3);
      height: 300px;
    }
  }

  @media (max-width: 650px) {
    .best_img {
      width: 70%;
      height: auto;
    }

    .gmiddlepane {
      text-align: center;
      width: 100%;
      height: 300px;
    }

    .gheading-text {
      font: 24px Podkova, serif;
      font-weight: 800;
      color: var(--color-darkslategrey-d26bdb37);
      white-space: pre-wrap;
    }

    .gsecondary-text {
      margin-top: 35px;
      font: 14px Rubik, sans-serif;
      font-weight: 400;
      color: rgba(81, 109, 133, 1);
      white-space: pre-wrap;
    }
  }