.container {
  background-image: url(../../pic/ggg231.png);
  background-size: cover;
  background-position: center top -30px;
  background-position: center bottom -10px;
  margin-top: 200px;
  width: 100%;
  background-color: #fff;
}

.toppane {
  text-align: center;
  width: 100%;
  height: 150px;
}

.leftpane {
  text-align: center;
  width: calc(100%/3);
  height: 150px;
}

.middlepane {
  text-align: center;
  width: calc(100%/3);
  height: 150px;
}

.rightpane {
  text-align: center;
  width: calc(100%/3);
  height: 150px;
}

body {
  margin: 0!important;
}

.d-flex {
  display: flex;
}

.heading-text {
  font: 32px Podkova, serif;
  font-weight: 800;
  color: var(--color-darkslategrey-d26bdb37);
  white-space: pre-wrap;
}

.secondary-text {
  font: 16px Rubik, sans-serif;
  font-weight: 400;
  color: rgba(81, 109, 133, 1);
  white-space: pre-wrap;
}

@media (max-width: 1400px) {
  .container {
    background-image: none;
  }
}

@media (max-width: 650px) {
  .heading-text {
    font: 24px Podkova, serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
  }
}