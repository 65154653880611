.main-heading-style {
    box-sizing: border-box;
    width: 100%;
    font: 32px Podkova, serif;
    font-weight: 900;
    color: var(--color-darkslategrey-d26bdb37);
    text-align: center;
    white-space: pre-wrap;
}
/* knopka */
.group-schedule-button {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 435px;
    min-width: 435px;
    height: 64px;
    font: var(--font-weight-400) var(--font-size-20) Podkova, serif;
    color: var(--color-darkslategrey-d26bdb37);
    cursor: pointer;
    background-color: rgba(194, 223, 234, 1);
    border: 3px solid;
    border-color: rgb(95, 132, 160);
    border-radius: 52px;
    box-shadow: 0px 6px 0px rgb(95, 132, 160);
    transition: all .2s;
    padding: 20px 25px;
    top: 0;
    margin:0 20px;
}
.group-schedule-button:active {
    top: 3px;
    box-shadow: 0 2px 0px rgb(95, 132, 160);
    transition: all .2s;
}

.about-con {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.video-welcome {
  width: 1000px;
  height: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
}
  
  .youtube {
    width: 100%; /* ширина видео */
    height: 100%; /* высота видео */
    margin: auto; /* центрируем видео */
  }

.group-schedule-button-container {
    margin-top: 30px;
}

  @media (max-width: 1600px) {
    .video-welcome {
      width: 900px;
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  @media (max-width: 1200px) {
    .video-welcome {
      width: 800px;
      height: 450px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 1000px) {
    .video-welcome {
      width: 700px;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 800px) {
    .video-welcome {
      width: 600px;
      height: 330px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .group-schedule-button-container {
      margin-top: 30px;
  }
  }

  @media (max-width: 500px) {
    .video-welcome {
      width: 400px;
      height: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

      .group-schedule-button {
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 250px;
        height: 64px;
        font: var(--font-weight-400) var(--font-size-20) Podkova, serif;
        color: var(--color-darkslategrey-d26bdb37);
        cursor: pointer;
        background-color: rgba(194, 223, 234, 1);
        border: 3px solid;
        border-color: rgb(95, 132, 160);
        border-radius: 52px;
        box-shadow: 0px 6px 0px rgb(95, 132, 160);
        transition: all .2s;
        padding: 20px 25px;
        top: 0;
        margin:0 0;
    }

    .about-con {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 60vh;
  }
  }