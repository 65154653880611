.forcontainer {
    background-color: #fff;
    margin-top: 200px;
    width: 100%;
  }
  
  .fortoppane {
    text-align: center;
    width: 100%;
    height: 150px;
  }
  
  .forleftpane {
    background-image: url(../pic/trianglefit.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10px -60px;
    text-align: center;
    width: calc(100%/4);
    height: 250px;
  }
  
  .formiddlepane {
    background-image: url(../pic/trianglefit.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10px -60px;
    text-align: center;
    width: calc(100%/4);
    height: 250px;
  }
  
  .forrightpane {
    background-image: url(../pic/trianglefit.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10px -60px;
    text-align: center;
    width: calc(100%/4);
    height: 250px;
  }
  
  body {
    margin: 0!important;
  }
  
  .ford-flex {
    margin-top: 40px;
    display: flex;
  }
  
  .forheading-text {
    margin-top: 100px;
    font: 32px Podkova, serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
  }

  .forheading-title {
    margin-top: 100px;
    font: 32px Podkova, serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
  }
  
  .forsecondary-text {
    font: 16px Rubik, sans-serif;
    font-weight: 400;
    color: rgba(81, 109, 133, 1);
    white-space: pre-wrap;
  }

  @media (max-width: 1870px) {
    .forcontainer {
      background-image: none;
      background-size: cover;
      background-position: center top -50px;
      background-position: center bottom -130px;
      margin-top: 250px;
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    .forbr {
      display: none;
    }

    .forheading-text {
      font: 28px Podkova, serif;
      font-weight: 800;
      color: var(--color-darkslategrey-d26bdb37);
      white-space: nowrap;
      margin-top: 0;
      padding-left: 15px;
    }
    
    .forsecondary-text {
      font: 16px Rubik, sans-serif;
      font-weight: 400;
      color: rgba(81, 109, 133, 1);
      white-space: pre-wrap;
      padding: 7px 20px;
    }

    .ford-flex {
      margin-top: 0;
      display: contents;
    }

    .forleftpane {
      display: flex;
      background-image: none;
      text-align: left;
      width: 100%;
      height: 70px;
    }
    
    .formiddlepane {
      display: flex;
      background-image: none;
      text-align: left;
      width: 100%;
      height: 70px;
    }
    
    .forrightpane {
      display: flex;
      background-image: none;
      text-align: left;
      width: 100%;
      height: 70px;
    }
  }

@media (max-width: 1500px) {
  .forleftpane {
    background-image: none;
  }
  
  .formiddlepane {
    background-image: none;
  }
  
  .forrightpane {
    background-image: none;
  }
}

@media (max-width: 800px) {
  .forleftpane {
    display: contents;
    background-image: none;
    text-align: left;
    width: 100%;
    height: 70px;
  }
  
  .formiddlepane {
    display: contents;
    background-image: none;
    text-align: left;
    width: 100%;
    height: 70px;
  }
  
  .forrightpane {
    display: contents;
    background-image: none;
    text-align: left;
    width: 100%;
    height: 70px;
  }
}