.specialists-container {
    margin-left: 100px;
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 400px;
  }
  
  .specialist-card {
    margin-right: 20px;
    margin-top: 70px;
    display: flex;
    width: 49%;
    border-radius: 24px;
    border: 1px solid rgba(206, 212, 214, 0.7);
  }

  .specialist-card.focused {
    position: fixed;
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  }

  .leading-online-group-plaque {
    position: absolute;
    margin-left: 37%;
    padding: 10px;
    background-color: rgba(194, 223, 234, 1);
    border-radius: 0 0 10px 10px;
    font: 16px Podkova, sans-serif;
    font-weight: 600;
    color: var(--color-darkslategrey-d26bdb37);
    border-bottom: 2px solid var(--color-darkslategrey-d26bdb37);
  }


  .expanded {
    width: 85%;
    flex-basis: 85%;
  }
  
  .specialist-image-container {
    width: 40%;
    height: 100%;
  }
  
  .specialist-image-container img {
    border-radius: 8px;
    width: 100%;
  }

  .specialist-info-container {
    background-size: cover;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .specialist-name {
    font: 35px Podkova, sans-serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
  }

  .specialist-description-text {
    font: 16px Rubik, sans-serif;
    font-weight: 400;
    color: rgba(81, 109, 133, 1);
    width: 65%;
    white-space: pre-wrap !important;
    font-size: 16px;
  }

  .tg-group-schedule-button {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 435px;
    min-width: 435px;
    height: 64px;
    font: var(--font-weight-400) var(--font-size-20) Podkova, serif;
    color: var(--color-darkslategrey-d26bdb37);
    cursor: pointer;
    background-color: rgba(194, 223, 234, 1);
    border: 3px solid;
    border-color: rgb(95, 132, 160);
    border-radius: 52px;
    box-shadow: 0px 6px 0px rgb(95, 132, 160);
    transition: all .2s;
    padding: 20px 25px;
    top: 0;
    margin:0 20px;
    margin-top: 30px;
}
.tg-group-schedule-button:active {
    top: 3px;
    box-shadow: 0 2px 0px rgb(95, 132, 160);
    transition: all .2s;
}

.specialist-description button {
  background-color: transparent;
  border: none;
  padding: 1px;
  cursor: pointer;
}

.specialist-description.arrow-down-icon {
  font: var(--font-weight-800) var(--font-size-20) Podkova, serif;
  color: var(--color-darkslategrey-d26bdb37);
  margin-left: 5px;
}

.button-show {
  border: none;
  background-color: transparent;
  color: rgb(147, 170, 179);
}

.button-show:hover {
  color: rgb(97, 112, 117);
}

.button-hide {
  border: none;
  background-color: transparent;
  color: rgba(191, 202, 133, 1);
}

.button-hide:hover {
  color: rgb(119, 126, 82);
}

@media (max-width: 1850px) {
  .tg-group-schedule-button {
    width: 60%;
    min-width: 60%;
    height: 64px;
    font-size: clamp(0.5rem, 0.25rem + 1.5vw, 1.2rem);
  }

  .leading-online-group-plaque {
    position: absolute;
    margin-left: 70%;
    padding: 10px;
    background-color: rgba(194, 223, 234, 1);
    border-radius: 0 0 10px 10px;
    font: 16px Podkova, sans-serif;
    font-weight: 600;
    color: var(--color-darkslategrey-d26bdb37);
    border-bottom: 2px solid var(--color-darkslategrey-d26bdb37);
  }

  .specialist-card {
    width: 100%;
  }

  .specialist-name {
    font-size: 32px;
  }

  .expanded {
    width: 100%;
    flex-basis: 100%;
  }
  .specialist-card.focused {
    width: 80vw;
    height: 80vh;
    overflow-y: auto;
    margin-top: 20px;
  }
}

@media (max-width: 1000px) {
  .tg-group-schedule-button {
    width: 100%;
    min-width: 60%;
    height: 64px;
    font-size: clamp(1rem, 0.25rem + 1.5vw, 2rem);
    margin:0 0px;
    margin-top: 15px;
    white-space: nowrap;
  }

  .leading-online-group-plaque {
    position: absolute;
    margin-left: 0%;
    padding: 10px;
    background-color: rgba(194, 223, 234, 1);
    border-radius: 0 0 10px 10px;
    font: 12px Podkova, sans-serif;
    font-weight: 600;
    color: var(--color-darkslategrey-d26bdb37);
    border-bottom: 2px solid var(--color-darkslategrey-d26bdb37);
  }

  .specialist-name {
    font-size: 24px;
  }

  .specialist-image-container {
    width: 80%;
    height: 100%;
  }

  .specialist-card {
    margin-right: 20px;
    margin-top: 20px;
    display: flex;
    width: 100%;
    border-radius: 24px;
    border: 1px solid rgba(206, 212, 214, 0.7);
  }

  .specialists-container {
    margin-left: 0;
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .specialist-description-text {
    font: 16px Rubik, sans-serif;
    font-weight: 400;
    color: rgba(81, 109, 133, 1);
    width: 100%;
    white-space: pre-wrap !important;
    font-size: 15px;
  }

  .spanbut {
    display: none;
  }

  .specialist-card.focused .specialist-image-container {
    display: none;
  }

  .specialist-card.focused .specialist-description-text {
    width: 100%;
  }

  .specialist-info-container {
    width: 100%;
  }
}