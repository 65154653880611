.schelude_heading {
    box-sizing: border-box;
    font: 32px Podkova, serif;
    font-weight: 900;
    color: var(--color-darkslategrey-d26bdb37);
    text-align: center;
    white-space: pre-wrap;
}

.schelude_con {
    margin-top: 200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.schelude_item {
  height: 120px;
  margin-top: 30px;
  background: linear-gradient(90deg, #83a0bb 0%, #CADDE4 100%);
  background-position: right bottom;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  cursor: pointer;
  }

  .schelude_item:hover {
    background: linear-gradient(90deg, #83a0bb 0%, #83a0bb 100%);
  }
  
  .schelude_button {
    font: 24px Podkova, serif;
    font-weight: 900;
    color: var(--color-darkslategrey-d26bdb37);
    text-align: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgba(202, 221, 228, 1);
    margin-right: 30px;
    border: 3px solid;
    border-color: var(--color-darkslategrey-d26bdb37);
  }

  .schelude_button:hover {
    color: rgba(202, 221, 228, 1);
    background-color: var(--color-darkslategrey-d26bdb37);
  }

.schelude_expanded {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
  
  .schelude_item.expanded .schelude_expanded {
    display: flex;
  }
  
  .modal {
    border-radius: 40px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #e0e0e0;
    z-index: 1000;
    width: 50%;
    height: auto;
    overflow: auto;
  }
  
  .modal_close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
  }
  
  .modal.visible {
    display: block;
  }

  .blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    filter: blur(2px);
    transition: filter 0.3s ease;
    z-index: 998;
    visibility: hidden;
  }
  
  .blur-background.visible {
    filter: blur(2px);
    visibility: visible;
  }

  .schelude_lesson_name {
    position: absolute; 
    left: 180px; 
    margin-top: 13px;
    font: 24px Podkova, serif;
    font-weight: 900;
    color: var(--color-darkslategrey-d26bdb37);
    text-align: left;
    white-space: wrap;
    width: 1000px;
    max-height: 100px;
  }

  .schelude_lesson_img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }

  .schelude_lesson_date {
    position: absolute; 
    right: 150px; 
    margin-top: 13px;
    font: 24px Podkova, serif;
    font-weight: 900;
    color: var(--color-darkslategrey-d26bdb37);
    text-align: center;
    white-space: pre-wrap;
  }

  @media (max-width: 1200px) {
    .schelude_lesson_date {
      display: none;
    }
  }

  @media (max-width: 1600px) {
    .schelude_lesson_name {
      position: absolute; 
      left: 180px; 
      margin-top: 13px;
      font: 24px Podkova, serif;
      font-weight: 900;
      color: var(--color-darkslategrey-d26bdb37);
      text-align: left;
      white-space: wrap;
      width: 500px;
      max-height: 100px;
    }
  
  }

  @media (max-width: 900px) {
    .schelude_item {
      height: 170px;
      margin-top: 30px;
      }
      .schelude_lesson_name {
        position: absolute; 
        left: 180px; 
        margin-top: 13px;
        font: 20px Podkova, serif;
        font-weight: 900;
        color: var(--color-darkslategrey-d26bdb37);
        text-align: left;
        white-space: wrap;
        width: 300px;
        max-height: 150px;
      }
  }

  @media (max-width: 700px) {
    .modal {

    }
  }

  @media (max-width: 600px) {
    .schelude_item {
      height: 170px;
      margin-top: 30px;
      padding: 0 10px;
      }
      .schelude_lesson_name {
        position: absolute; 
        left: 180px; 
        margin-top: 13px;
        font: 18px Podkova, serif;
        font-weight: 900;
        color: var(--color-darkslategrey-d26bdb37);
        text-align: left;
        white-space: wrap;
        width: 200px;
        max-height: 150px;
      }
  }

  @media (max-width: 450px) {
    .schelude_button {
      display: none;
    }
  }

  /* ------------------------------------------------------------------------------------------------------- */

.modal_psychotherapy-platform-layout {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.modal_image-container-1 {
    box-sizing: border-box;
    display: block;
    width: 500px;
    max-width: initial;
    border-radius: 30px;
    height: auto;
    border: none;
    object-fit: cover;
    align-self: flex-start;
    margin-top: 20px;
}
.modal_psychotherapy-info-block {
    flex: 0 1 auto;
    align-self: flex-start;
    padding-bottom: 50px;
    margin-left: 23px;
}
.modal_psychotherapy-platform-welcome {
    width: 100%;
    padding-right: 3px;
    padding-left: 3px;
    margin-top: 30px;
}
.modal_majestic-heading-name {
    margin-top: 20px;
    margin-left: 25px;
    padding-top: 13px;
    font: 24px Podkova, serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
}
.modal_majestic-heading-1 {
    font: 24px Podkova, serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
}
.modal_majestic-heading-2 {
    margin-top: 20px;
    font: 20px Podkova, serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
}
.modal_psychotherapy-text-style {
    box-sizing: border-box;
    width: 100%;
    font: 16px Rubik, sans-serif;
    font-weight: 400;
    color: rgba(81, 109, 133, 1);
    text-align: left;
}
.modal_content-wrapper-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.modal_image-avatar {
    box-sizing: border-box;
    display: block;
    width: 41px;
    max-width: initial;
    height: 41px;
    border: none;
    object-fit: cover;
}
.modal_image-container-with-border {
    box-sizing: border-box;
    display: block;
    width: 31px;
    max-width: initial;
    height: 31px;
    margin-left: 14px;
    border: none;
    object-fit: cover;
}
.modal_group-schedule-button-container {
    margin-top: 50px;
}

.modal_specialist_name_con {
    height: 60px;
    background-image: url(../pic/Polygon\ 104.png);
    background-repeat: no-repeat;
}
/* knopka */
.modal_group-schedule-button {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 435px;
    min-width: 435px;
    height: 64px;
    font: var(--font-weight-400) var(--font-size-20) Podkova, serif;
    color: var(--color-darkslategrey-d26bdb37);
    cursor: pointer;
    background-color: rgba(194, 223, 234, 1);
    border: 3px solid;
    border-color: rgb(95, 132, 160);
    border-radius: 52px;
    box-shadow: 0px 6px 0px rgb(95, 132, 160);
    transition: all .2s;
    padding: 20px 25px;
    top: 0;
    margin:0 20px;
}
.modal_group-schedule-button:active {
    top: 3px;
    box-shadow: 0 2px 0px rgb(95, 132, 160);
    transition: all .2s;
}

.softmerge-inner {
  width: 100% !important;
  left: 0 !important;
}

@media (max-width: 2300px) {
  .modal {
    top: 50%;
    left: 50%;
    width: 80%;
    height: 60%;
    max-height: 800px;
  }
}

@media (max-width: 1400px) {
  .modal_image-container-1 {
    width: 400px;
}
}

@media (max-width: 1200px) {
  .modal_psychotherapy-platform-layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .modal_psychotherapy-platform-layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .modal_image-container-1 {
    display: none;
}

.modal_group-schedule-button-container {
  padding-top: 20px;
  padding-left: 0;
}
}

@media (max-width: 800px) {
  .modal {
    margin-top: 100px;
    height: 100vh;
    max-height: 1000px;
  }

  .modal_group-schedule-button {
    width: 100%;
    min-width: 0;
    text-align: center;
    white-space: nowrap;
    padding: 20px 25px;
    margin:0 0;
}

.modal_group-schedule-button-container {
  padding-bottom: 50px;
}

.modal_psychotherapy-text-style {
  text-align: left;
}

.modal_majestic-heading-1 {
  text-align: left;
}

.modal_image-container-with-border {
  margin-left: 0px;
}

.modal_psychotherapy-info-block {
  margin-left: 0;
}

.modal_majestic-heading-name {
  margin-top: 20px;
  margin-left: 0;
  padding-top: 13px;
  font: 20px Podkova, serif;
  font-weight: 800;
  color: var(--color-darkslategrey-d26bdb37);
  white-space: pre-wrap;
  text-align: center;
}

.modal_specialist_name_con {
  height: 60px;
  width: 100%;
  background-image: none;
}

.modal_psychotherapy-platform-welcome {
  padding-right: 3px;
  padding-left: 3px;
}

.modal_majestic-heading-2 {
  margin-top: 20px;
  font: 20px Podkova, serif;
  font-weight: 800;
  color: var(--color-darkslategrey-d26bdb37);
  white-space: pre-wrap;
}
}

