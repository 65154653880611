.scheme{
    padding-bottom: 100px;
    margin-top: 100px;
}

.horizontal-lists-container {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../pic/way3.svg);
    background-size: 1258px 28px;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .horizontal-list-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    gap: 300px;
  }
  
  .horizontal-list-container.second-list {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    gap: 300px;
  }
  
  .horizontal-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    border-radius: 5px;
    max-width: 200px;
    text-align: center;
    font: 20px Podkova, serif;
    font-weight: 600;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
  }

  .horizontal-list-item.first-item {
    padding-left: 100px;
    font-weight: 800;
  }

  .horizontal-list-item.last-item {
    padding-right: 100px;
    font-weight: 800;
  }

  .schemeheading-text {
    font: 32px Podkova, serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
  }

  .main-schemeheading-text {
    font: 24px Podkova, serif;
    font-weight: 800;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
  }

  .schemetoppane {
    text-align: center;
    width: 100%;
  }

  .main-prog-con {
    margin-top: 60px;
    text-align: center;
    width: 100%;
  }

  .semi-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .semi-item {
    display: flex;
    align-items: center;
    gap: 13px;
    height: 34px;
  }

  .semi-list-div {
    margin-top: 48px;
    justify-content: center;
    padding: 0 25%;
  }

  .triangle {
    padding-bottom: 14px;
  }

  .semi-text {
    font: 20px Podkova, serif;
    font-weight: 600;
    color: var(--color-darkslategrey-d26bdb37);
    white-space: pre-wrap;
  }

@media (max-width: 1450px) {
  .scheme {
    display: none;
  }
}